import { handleErrorWithSentry, Replay } from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';
import { env } from '$env/dynamic/public';

const environment = env.PUBLIC_ENVIRONMENT || 'development';

Sentry.init({
	dsn: 'https://6970b6547eab96ed557c8d50b2ee34d2@o4505845502902272.ingest.sentry.io/4505912563793920',
	tracesSampleRate: 1.0,

	// This sets the sample rate to be 10%. You may want this to be 100% while
	// in development and sample at a lower rate in production
	replaysSessionSampleRate: 0.1,

	// If the entire session is not sampled, use the below sample rate to sample
	// sessions when an error occurs.
	replaysOnErrorSampleRate: 1.0,

	// If you don't want to use Session Replay, just remove the line below:
	integrations: [new Replay()],
	
	// we disable all message sending (in development)
	
	enabled: environment === "development" ? false : true,
	
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
