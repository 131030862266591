import * as client_hooks from '../../../src/hooks.client.js';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27')
];

export const server_loads = [0];

export const dictionary = {
		"/": [~2],
		"/activate/[uid]/[token]": [~3],
		"/coefficient-data": [~4],
		"/complete_registration/[uid]/[token]": [~5],
		"/disclaimer": [6],
		"/forgot-password": [~7],
		"/info": [8],
		"/login": [~9],
		"/logout": [~10],
		"/market-data": [~11],
		"/projects": [~12],
		"/projects/new": [~20],
		"/projects/[id]": [~13],
		"/projects/[id]/asset-data": [~14],
		"/projects/[id]/asset-data/filter": [~16],
		"/projects/[id]/asset-data/[asset_data_id]": [~15],
		"/projects/[id]/edit": [~17],
		"/projects/[id]/reports/[report_id]": [~18],
		"/projects/[id]/reports/[report_id]/full": [~19],
		"/public/[signature]": [~21],
		"/reset_password_confirm/[uid]/[token]": [~22],
		"/sentry-example": [23],
		"/terms": [24],
		"/users": [~25],
		"/users/new": [~27],
		"/users/[id]": [~26]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';